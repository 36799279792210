// Components
import CardItem from "../CardItem"
import LoanItem from "../LoanItem"
import AcquirerItem from "../AcquirerItem"
import AccountItem from "../AccountItem"

const ItemListing = ({ content, handleClick, position }) => {
  const { component } = content

  const itemListing = {
    marketplace_product_card: (
      <CardItem position={position} handleClick={handleClick} data={content} />
    ),
    marketplace_redirect_card: (
      <CardItem position={position} handleClick={handleClick} data={content} />
    ),
    marketplace_product_acquirer: <AcquirerItem data={content} />,
    marketplace_product_accounts: <AccountItem data={content} />,
    marketplace_product_loans: <LoanItem data={content} />,
    default: <></>,
  }

  return itemListing[component] || itemListing["default"]
}

export default ItemListing
