export const parsePageContentAdobe = ({ pageContent }) => {
  const splittedPath = window?.location?.pathname?.split("/")?.filter((item) => !!item)
  const productList = parseCardList({pageContent, splittedPath})
  return {
    pageName: window?.document?.title,
    siteName: "Mastercard : BR",
    hierarchy: window?.location?.pathname?.slice(1)?.replace(/\//g, "|"),
    siteSection1: splittedPath?.[0] || "",
    siteSection2: splittedPath?.[1] || "",
    siteSection3: splittedPath?.[2] || "",
    siteType: "promotional:regional",
    pageURL: window?.location?.href,
    pageTitle: window?.document?.title,
    region: "LAM",
    country: "BR",
    language: "pt-br",
    pageType: "B2C",
    issuerName: pageContent?.issuer || "",
    cardName: pageContent?.title || "",
    cardID: pageContent?.product_slug || "",
    categoryName: splittedPath?.[1] || "",
    subcategoryName: splittedPath?.[2] || "",
    eventInfo: { eventName: isSubCategoryPage({categoryPathName: splittedPath?.[1]}) ? "subCategorySelected" : ""},
    productItems: productList || [],
    numberOfCards: pageContent?.product_list?.[0]?.list?.length
  }
}

const parseCardList = ({ pageContent, splittedPath }) => {
  const productArray = pageContent?.product_list?.[0]?.list?.map((item) => {
    return {
      cardName: item?.content?.title,
      cardId: item?.id,
      category: getCategory({categoryPathName: splittedPath?.[1]}) || "",
      subCategory: getSubCategory({content: pageContent, categoryPathName: splittedPath?.[2]}) || "",
      issuerName: item?.content?.issuer
    }
  })

  return productArray
}

export const getCategory = ({categoryPathName}) => {
  switch (categoryPathName) {
    case ("emissores") :
      return "Bank"
    case ("categorias") :
      return "Benefit"
    case ("ver-todos-os-cartoes-de-credito") :
      return "Browse All Cards"
    case("cartao") :
      return "Card"
    default :
      return ""
  }
}

export const getSubCategory = ({content, categoryPathName}) => {
  return content?.hero?.[0]?.title ||
    categoryPathName ||
    ""
}

export const getCardId = ({card, content}) => {
  const cardList = content?.content?.product_list?.[0]?.list || []

  const cardInfoArray = cardList.filter((item) => {
    return item?.content?.product_slug === card?.product_slug
  })

  return cardInfoArray?.[0]?.id
}

export const getCardIdFromEditable = (text) => {
  const idRegex = /"id": "(?<id>.*)"/

  return text?.match(idRegex)?.groups?.id
}

const isSubCategoryPage = ({categoryPathName}) => {
  return categoryPathName === "emissores" || categoryPathName === "categorias"
}
