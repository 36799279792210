import delay from "@mkt/utils/delay"

import useAnalytics from "@mkt/ui/hooks/useAnalytics"

// Utils
import getQueryParams from "@mkt/utils/getQueryParams"
import useStoryblok from "@mkt/utils/storyblok/useStoryblok"
import { getCardId, getCategory, getSubCategory } from "@mkt/analytics/components/AnalyticsProvider/helpers/adobePayload"
import { isOneTrustActive } from "@mkt/mastercard/src/utils/isOneTrustActive"
import { navigate } from "gatsby"
import { useEffect, useRef, useState } from "react"
import { useTrack } from "@mkt/analytics/redline/track"

const useListingPage = ({ pageContext }) => {
  const currentListId = "lista-de-cartoes"
  const currentListName = "Lista de cartões"

  // State
  const [heroTitle, setHeroTitle] = useState("Listagem de Cartões")
  const [products, setProducts] = useState([])
  const [categoriesList, setCategoriesList] = useState([])
  const [issuersList, setIssuersList] = useState([])
  const [loading, setLoading] = useState(true)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(null)
  // Refs
  const formFilterRef = useRef(null)

  const { track } = useTrack()
  const data = useStoryblok(pageContext.content)
  const content = data?.content || pageContext.content
  const filters = pageContext.filters
  const [, , namePage] = content.component.split("_")
  const [sectionProductList] = content.product_list
  const { title: sectionTitle } = sectionProductList

  const { product, productsList, list, trackersLoaded } = useAnalytics()

  useEffect(() => {
    setCategoriesList(getFiltersFromPageList('/categorias/'))
    setIssuersList(getFiltersFromPageList('/emissores/'))
    if (list) {
      list.setList({ id: currentListId, name: currentListName })
    }
  }, [])

  useEffect(() => {
    if (trackersLoaded && products && selectedFilters)
      track("productListFiltered", {
        filters: selectedFilters,
        products,
        list: currentListId,
      })
  }, [products, trackersLoaded, selectedFilters])

  const setFalseLoading = () => {
    setLoading(false)
  }

  const getFiltersFromPageList = (slugUrl) => {
    let filtersList = []
    pageContext?.landings?.map((item) => {
      if(item?.node?.full_slug?.indexOf(slugUrl) > -1){
        filtersList.push(item.node)
      }
    })
    return filtersList
  }

  const getProducts = () => {
    const userSelectedList = content.product_list[0].list
    const onlySelected = content.product_list[0].list_only_selected === "true"

    const filterDiff = (product) =>
      !userSelectedList.find((item) => item.uuid === product.node.uuid)

    const filterComponent = (product) => {
      return product.node.field_component.split("_")[2] === namePage
    }

    const filterJeitto = (product) => product.node.slug !== "jeitto"
    const filterItauPf = (product) => product.node.slug !== "itau-pf"
    const filterItauPj = (product) => product.node.slug !== "itau-pj"

    const parseContent = (item) => ({
      ...item.node,
      content: JSON.parse(item.node.content),
    })

    const filteredProductsDiff = pageContext.products
      .filter(filterComponent)
      .filter(filterDiff)
      .filter(filterJeitto)
      .filter(filterItauPf)
      .filter(filterItauPj)
      .map(parseContent)

    const selectedProductsList = [...userSelectedList, ...filteredProductsDiff]

    const parseList = (list) =>
      list.map((product) => ({
        ...product.content,
        full_slug: product.full_slug?.replace("products", ""),
      }))

    const parsedProducts = parseList(selectedProductsList)
    const parsedProductsSelected = parseList(userSelectedList)

    return onlySelected ? parsedProductsSelected : parsedProducts
  }

  const _setProducts = () => {
    const allProducts = getProducts()

    setProducts(allProducts)

    if (productsList) {
      productsList.setProductsList(allProducts)
    }
  }

  const getFilters = () => {
    const listingPageType = {
      marketplace_listing_card: "card",
      marketplace_list_loans: "loans",
      marketplace_list_acquirer: "acquirer",
      marketplace_list_accounts: "accounts",
    }

    let componentFilters = {}
    Object.entries(filters).forEach((filter) => {
      componentFilters = {
        ...componentFilters,
        [filter[0]]: filter[1].filter((item) => {
          const dimensions = item?.dimension_value?.split(",")

          return dimensions?.includes(listingPageType[content.component])
        }),
      }
    })

    return componentFilters
  }

  const getProductIncomeValue = (product) => {
    const incomeString = product.minimum_income.split(",")[0]
    return Number(incomeString.replace(/\D/g, ""))
  }

  const handleNavigate = ({ path, state }) => {
    return navigate(path, { state });
  }

  const handleCardClick = (card, position, slug) => {
    const cardSlug = slug ? slug : card.full_slug
    const navigateUrl = card?.redirect_url ? card?.redirect_url : cardSlug

    product.setProduct({ ...card, list, position, slug })

    if (isOneTrustActive()) {
      const splittedPath = window?.location?.pathname?.split("/")?.filter((item) => !!item)

      window.di.productItems = [
        {
          cardName: card?.title,
          cardId: getCardId({card, content: pageContext}),
          category: getCategory({categoryPathName: splittedPath?.[1]}) || "",
          subCategory: getSubCategory({content: pageContext?.content, categoryPathName: splittedPath?.[2]}) || "",
          issuerName: card?.issuer
        }
      ]
      window.di.eventInfo = {
        eventName: "knowMoreClicked"
      }
      window.di.elementClicked = "button"

      setTimeout(() => {
        handleNavigate({
          path: navigateUrl,
          state: {
            category: getCategory({categoryPathName: splittedPath?.[1]}) || "",
            subCategory: getSubCategory({content: pageContext?.content, categoryPathName: splittedPath?.[2]}) || "",
          },
        })
      }, 1000)
    } else {
      handleNavigate({ path: navigateUrl })
    }

  }

  const handleToggleFilter = (e) => {
    e.preventDefault()
    return setIsFilterOpen(!isFilterOpen)
  }

  const handleFilter = (filters) => {
    let filteredProducts = getProducts()

    if (filters.category) {
      filteredProducts = filteredProducts.filter((product) =>
        product?.category?.includes(filters.category)
      )
      const selectedCategory = pageContext.filters.categories.filter(
        (category) => category.value === filters.category
      )
      setHeroTitle(selectedCategory[0]?.name)
    }

    if (filters.acquirer_type) {
      filteredProducts = filteredProducts.filter((product) =>
        product?.acquirer_type?.includes(filters.acquirer_type)
      )
    }

    if (filters.account_type) {
      filteredProducts = filteredProducts.filter((product) =>
        product?.account_type?.includes(filters.account_type)
      )
    }

    if (filters.issuer) {
      filteredProducts = filteredProducts.filter(
        (product) => product?.issuer === filters.issuer
      )
    }

    if (filters.assurance) {
      filteredProducts = filteredProducts.filter(
        (product) => product?.assurance === filters.assurance
      )
    }

    if (filters.income)
      filteredProducts = filteredProducts.filter((product) => {
        const income = getProductIncomeValue(product)
        const filterIncomeArr = JSON.parse(filters.income)

        if (income >= filterIncomeArr[0] && income <= filterIncomeArr[1])
          return product
      })

    setProducts(filteredProducts)

    if (productsList) {
      productsList.setProductsList(filteredProducts)
    }
  }

  const handleFilterSubmit = (e) => {
    e.preventDefault()

    // Get form items
    const filters = Object.fromEntries(new FormData(e.target))

    // Generate query string
    const queryString = new URLSearchParams(filters).toString()
    navigate(`${window.location.pathname}?${queryString}`)

    handleFilter(filters)
    setSelectedFilters(filters)

    // Close filter sidedrawer
    handleToggleFilter(e)
  }

  const handleClearFilter = (e) => {
    e.preventDefault()

    // Reset form values
    formFilterRef?.current?.reset()

    // Clear query string
    navigate(window.location.pathname)

    handleFilter({})

    // Reset hero title
    setHeroTitle("Listagem de Cartões")

    // Close filter sidedrawer
    handleToggleFilter(e)
  }

  const _getFiltersFromQuery = async () => {
    const queryParams = getQueryParams()

    // check if query params is empty
    if (Object.keys(queryParams).length === 0) {
      setFalseLoading()
      return
    }

    // Set form filter values
    const elements = formFilterRef?.current?.elements
    let filters = {}
    Object.entries(queryParams).map(([key, value]) => {
      if (elements && elements[key])
        elements[key].value = decodeURIComponent(value)

      filters = { ...filters, [key]: decodeURIComponent(value) }
    })

    await delay(500)

    handleFilter(filters)
    setSelectedFilters(filters)
    setFalseLoading()
  }

  useEffect(_setProducts, [])

  useEffect(_getFiltersFromQuery, [])

  return {
    content,
    heroTitle,
    loading,
    sectionTitle,
    products,
    namePage,
    filters: getFilters(),
    isFilterOpen,
    formFilterRef,
    handleFilterSubmit,
    handleClearFilter,
    handleFilter,
    handleToggleFilter,
    handleCardClick,
    handleNavigate,
    categoriesList,
    issuersList,
  }
}

export default useListingPage
