export const filtersData = {
  card: {
    issuer: {
      title: "Banco ou emissor",
      subtitle: "Filtre por banco ou emissor",
    },
    category: {
      title: "Benefício",
      subtitle: "Filtre por tipo de benefício",
    },
  },
  acquirer: {
    issuer: {
      title: "Marcas",
      subtitle: "Escolha por marcas mais conhecidas",
    },
    category: {
      title: "Características",
      subtitle: "Escolha a partir das características dos produtos",
    },
    acquirerType: {
      title: "Tipos de Máquina",
      subtitle: "Escolha por diferentes tipos de máquina",
    },
  },
  loans: {
    issuer: {
      title: "Marcas",
      subtitle: "Filtre por Tipo de Marca",
    },
    category: {
      title: "Benefícios",
      subtitle: "Filtre por tipo de benefício",
    },
  },
  accounts: {
    issuer: {
      title: "Marcas",
      subtitle: "Filtre por Tipo de Marca",
    },
    category: {
      title: "Benefícios",
      subtitle: "Filtre por tipo de benefício",
    },
    accountType: {
      title: "Tipo da conta",
      subtitle: "Filtre por tipo da conta",
    },
  },
}

export const incomeOptions = [
  { id: 0, name: "Até R$ 799", value: "[0,799]" },
  { id: 1, name: "R$ 800 a R$ 1999", value: "[800,1999]" },
  { id: 2, name: "R$ 2000 a R$ 4999", value: "[2000,4999]" },
  { id: 3, name: "Acima de R$ 5000", value: "[5000,99999]" },
]
