import { Link, navigate } from "gatsby"

import useAccountItem from "./hooks"

// Components
import Button from "@mkt/ui/components/Azulis/Button"
import { CheckBlue } from "@mkt/ui/icons/Azulis"
import Text from "@mkt/ui/components/Azulis/Text"
import Img from "@mkt/ui/components/common/Img"

// Styles
import * as S from "./styles"

const AccountItem = ({ data }) => {
  const { image, title_account: title, details, full_slug: fullSlug } = data

  const { filteredDetails } = useAccountItem({ details })

  return (
    <div css={S.AccountWrapper}>
      <figure css={S.ImageContainer}>
        <Img src={image?.filename} alt={image?.alt} />
      </figure>
      <section css={S.SectionInfo}>
        <Text as="h4" variant={[S.SectionInfoTitle]}>
          Conta Digital {title}
        </Text>
        <ul css={S.ListInfo}>
          {filteredDetails?.map((item, index) => (
            <li css={S.ListInfoItem} key={`item-account-details-${index}`}>
              <CheckBlue />
              <Text as="p" variant={["Text", "Text14", S.ListItemText]}>
                {item.title}:
                <Text as="span" variant={["Text", "Text14", "SemiBold"]}>
                  {item.desc.content[0].content[0].text}
                </Text>
              </Text>
            </li>
          ))}
        </ul>
      </section>
      <section css={S.SectionCtas}>
        <Button onClick={() => navigate(fullSlug)}>Peça já</Button>
        <Link to={fullSlug}>
          <Text as="span" variant={["Text14", "SemiBold"]}>
            Saiba mais
          </Text>
        </Link>
      </section>
    </div>
  )
}

export default AccountItem
