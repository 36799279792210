import { css, keyframes } from "@emotion/react"
import { fadeIn } from "@mkt/ui/styles/Keyframes"

export const accordion = css`
  position: relative;
  padding: 24px 0 8px;
  max-width: 680px;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
`

const rotateIn = keyframes`
  0% {
    opacity: .5;
    transform: rotate(160deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
`
const rotateOut = keyframes`
  0% {
    transform: rotate(-160deg);
    opacity: .5;
  }

  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
`

export const accordionIcon = css`
  position: absolute;
  top: 25px;
  right: 0;
  width: 17px;
  height: 17px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='17' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 16.5V.5m8 8H.5' stroke='%230504EB' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E");
  animation: ${rotateOut} 0.42s forwards;

  &.--open {
    float: right;
    height: 1px;
    background-image: none;
    background-color: #0504eb;
    margin-top: 8px;
    animation: ${rotateIn} 0.42s forwards;
  }
`

export const accordionTitle = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
`

export const accordionSubtitle = css`
  font-size: 12px;
  line-height: 18px;
`

export const accordionContent = css`
  display: block;
  padding: 12px 0 16px;
  margin-top: -1px;
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  max-width: 680px;

  &.--open {
    opacity: 1;
    visibility: visible;
    animation: ${fadeIn} 0.42s forwards;
  }

  &.--closed {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
`

export const contentOption = css`
  position: relative;
  font-size: 16px;

  &:hover {
    background-color: #e1e1e1;
    padding-left: 66px;
    margin-left: -66px;
    margin-right: -66px;
  }

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    &[type="checkbox"] ~ [for]:before,
    &[type="radio"] ~ [for]:before {
      border-radius: 50%;
      background-size: auto !important;
    }

    &[type="checkbox"]:checked ~ [for]:before,
    &[type="radio"]:checked ~ [for]:before {
      background-color: #0504eb;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.44l2.796 2.91L9 0' stroke='%23FFF' fill='none'/%3E%3C/svg%3E");
      border: 1px solid #0504eb;
    }
  }

  label {
    display: flex;
    align-items: center;
    padding: 16px 0;

    &::before {
      content: "";
      background-repeat: no-repeat;
      background-size: 12px 18px;
      display: inline-block;
      width: 19px;
      height: 19px;
      border: 1px solid #656565;
      margin-right: 20px;
      background-position: 50%;
      border-radius: 2px;
    }
  }
`
