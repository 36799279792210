import * as S from "./styles"
import useLoading from "./useLoading"

const Loading = () => {
  const { itemsList } = useLoading()

  return (
    <div>
      {itemsList.map((_, index) => {
        return (
          <div css={S.ContainerLoading} key={`skeleton-ui-${index}`}>
            <div css={S.LoadingContentImage}>
              <div css={S.LoadingImage}></div>
            </div>
            <ul css={S.LoadingInformation}>
              <li css={S.LoadingTitle}></li>
              <li css={S.LoadingText}></li>
              <li css={S.LoadingText}></li>
              <li css={S.LoadingText}></li>
            </ul>
            <ul css={S.LoadingContentButton}>
              <li css={S.LoadingButton}></li>
              <li css={S.LoadingMore}></li>
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default Loading
