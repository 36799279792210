import { css } from "@emotion/react"
import { below } from "@mkt/ui/styles/Breakpoints"
import { colors } from "@mkt/ui/styles/Tokens"

export const listingCard = css`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;

  &:not(:first-of-type) {
    margin: 8px 0 0 0;
  }

  ${below["m"]} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
`

export const cardImage = css`
  justify-self: center;
  max-width: 180px;

  img {
    width: 100%;
    object-fit: contain;
  }
`

export const cardDescription = css`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
  height: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background: ${colors.clear};
  }

  > p {
    font-size: 14px;
    line-height: 24px;
  }

  ${below["m"]} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

    &:after {
      top: calc(100% + 20px);
      left: 0;
      width: 100%;
      height: 1px;
    }

    &:before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${colors.clear};
    }
  }
`

export const descriptionContent = css`
  h2 {
    font-size: 18px;
    line-height: 1.44em;
    font-weight: 700;
    margin: 0;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    color: ${colors.gray.gray_50};
    margin: 12px 0 0 0;

    &:last-of-type {
      margin: 4px 0 0 0;
    }

    span {
      display: block;
      font-weight: 700;
      color: black;
    }
  }
`

export const cardLinks = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${below["m"]} {
    flex-direction: row-reverse;
    justify-content: space-around;
    width: 100%;
  }

  a {
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    margin: 25px 0 0 0;

    ${below["m"]} {
      margin: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }
`
