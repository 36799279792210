import { Link, navigate } from "gatsby"
import { render } from "storyblok-rich-text-react-renderer"

import useLoanItem from "./hooks"

import BlueCheckIcon from "@mkt/azulis/static/images/icons/check-blue.svg"

// Components
import Button from "@mkt/ui/components/Azulis/Button"
import Text from "@mkt/ui/components/Azulis/Text"
import Img from "@mkt/ui/components/common/Img"

// Styles
import * as S from "./styles"

const LoanItem = ({ data }) => {
  const {
    image,
    title,
    full_slug: fullSlug,
    loans_info: loansInfo,
    minimum_interest: minimumInterest,
  } = data

  const { sectionListInfoLoans } = useLoanItem({ loansInfo })

  return (
    <div css={S.LoanWrapper}>
      <figure css={S.ImageContainer}>
        <Img src={image?.filename} alt={image?.alt} />
      </figure>
      <section css={S.SectionInfo}>
        <Text as="h4" variant={[S.SectionInfoTitle]}>
          Empréstimo {title}
        </Text>
        <ul css={S.ListInfo}>
          {sectionListInfoLoans?.map((infoLoans, index) => {
            const { _uid: uuid, title, desc } = infoLoans
            const idKeyForRender = `#list-loans-${uuid}-${index}`

            return (
              <li css={S.ListInfoItem} key={idKeyForRender}>
                <img
                  css={S.ListDotImage}
                  src={BlueCheckIcon}
                  alt={"Check Icon"}
                />
                <Text as="span" variant={["Text", "Text14", S.ListItemText]}>
                  {title}:
                  <Text as="strong" variant={["Text", "Text14", "SemiBold"]}>
                    {render(desc)}
                  </Text>
                </Text>
              </li>
            )
          })}
        </ul>
      </section>
      <section css={S.SectionFeesInfo}>
        <Text as="h4" variant={["HeadingTwo", "SemiBold", S.FeesTitle]}>
          {minimumInterest}
        </Text>
        <Text as="p" variant={["Text14", "TextSubTitle", S.FeesSubtitle]}>
          Juros mínimo ao mês
        </Text>
        <div css={S.SectionFeesButtonContent}>
          <div css={S.ContainerButton}>
            <Button onClick={() => navigate(fullSlug)}>Peça já</Button>
          </div>
          <Link to={fullSlug}>
            <Text as="span" variant={["Text14", "SemiBold", S.LinkStyle]}>
              Saiba mais
            </Text>
          </Link>
        </div>
      </section>
    </div>
  )
}

export default LoanItem
