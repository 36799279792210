import { useState } from "react"

const useAccordion = () => {
  // States
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  // Functions
  const handleToggleAccordion = () => {
    return setIsAccordionOpen(!isAccordionOpen)
  }

  return { isAccordionOpen, handleToggleAccordion }
}

export default useAccordion
