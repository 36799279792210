import { css } from "@emotion/react"
import { above, below } from "@mkt/ui/styles/Breakpoints"

export const drawer = css`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  transition: opacity 0.4s, transform 0.4s;
  backface-visibility: hidden;
  z-index: 14;

  &.--inactive {
    opacity: 0;
    visibility: hidden;

    > div {
      transform: translateX(100%);
    }
  }

  &.--active {
    opacity: 1;
    visibility: visible;

    > div {
      transform: translateX(0);
    }
  }
`
export const drawerWrapper = css`
  position: absolute;
  right: 0;
  width: 70%;
  height: 100%;
  background: #fff;
  transition: all 0.4s;
  z-index: 10;
  overflow: auto;

  ${below["m"]} {
    width: 100%;
  }
`

export const drawerOverlay = css`
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
`
