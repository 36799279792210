const useLoading = () => {
  const minimunItems = 1
  const maximumItems = 4
  const numberItems = Math.floor(
    Math.random() * (maximumItems - minimunItems) + minimunItems
  )
  const itemsList = Array(numberItems).fill("Item")

  return {
    itemsList,
  }
}

export default useLoading
