import Icon from "@mkt/ui/components/common/Icon"

export default function CheckBlue(props) {
  return (
    <Icon
      iconMarkup={
        '<?xml version="1.0" encoding="UTF-8"?> <svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <!-- Generator: Sketch 58 (84663) - https://sketch.com --> <title>Stroke 1</title> <desc>Created with Sketch.</desc> <g id="Empréstimo---Listagem-Comparador---Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"> <g id="01---Home---Listagem-Comparador-Empréstimo---Desktop" transform="translate(-740.000000, -815.000000)" stroke="#0504EB"> <polyline id="Stroke-1" points="741 820.16 745.194 824.525 753 815"></polyline> </g> </g> </svg>'
      }
      iconName="CheckBlue"
      {...props}
    />
  )
}
