import { Link, navigate } from "gatsby"

// Components
import Img from "@mkt/ui/components/common/Img"
import Text from "@mkt/ui/components/Azulis/Text"
import Button from "@mkt/ui/components/Azulis/Button"

// Styles
import * as S from "./styles"

const AcquirerItem = ({ data }) => {
  return (
    <div css={S.listingAcquirer}>
      <div css={S.acquirerInfos}>
        <Img src={data?.image?.filename} alt={data?.image?.alt} />
        <div className="infos__content">
          <Text as="h2">{data?.title}</Text>
          <div className="content__price">
            {data?.price_old && (
              <p className="acquirer__discount acquirer__discount--mobile">{`De 12x de R$ ${data?.price_old}`}</p>
            )}
            <h3
              className={`acquirer__price acquirer__price--mobile${
                data?.price_old ? " acquirer__price--discount" : ""
              }`}
            >
              {`R$ ${data?.price}`}
            </h3>
          </div>
        </div>
      </div>
      <hr css={S.acquirerDivider} />
      <div css={S.acquirerFees}>
        <Text as="h3">Taxas</Text>
        <div css={S.feesContent}>
          <Text>
            Débito <span>{data?.fees?.tbody[0]?.body[1]?.value}</span>
          </Text>
          <Text>
            Crédito (de 0 a 2 dias){" "}
            <span>{data?.fees?.tbody[1]?.body[1]?.value}</span>
          </Text>
        </div>
      </div>
      <hr css={S.acquirerDivider} />
      <div css={S.acquirerLinks}>
        {data?.price_old && (
          <p className="acquirer__discount">{`De 12x de R$ ${data?.price_old}`}</p>
        )}
        <h3
          className={`acquirer__price${
            data?.price_old ? " acquirer__price--discount" : ""
          }`}
        >
          {`R$ ${data?.price}`}
        </h3>
        <Button
          size="small"
          color={data?.price_old && "inverted"}
          onClick={() => navigate(data?.full_slug)}
        >
          Peça já
        </Button>
        <Link to={data?.full_slug}>Saiba mais</Link>
      </div>
    </div>
  )
}

export default AcquirerItem
