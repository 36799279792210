import limitText from "@mkt/utils/limitText"
import { Link, navigate } from "gatsby"

// Components
import Button from "@mkt/ui/components/Azulis/Button"
import Text from "@mkt/ui/components/Azulis/Text"
import Img from "@mkt/ui/components/common/Img"

// Styles
import * as S from "./styles"

const CardItem = ({ data, handleClick, position }) => {
  const {
    image,
    title,
    minimum_income: minimumIncome,
    annuity,
    description,
    full_slug: fullSlug,
  } = data

  return (
    <div css={S.listingCard} data-scroll="productViewed" data-position={position}>
      <div css={S.cardImage}>
        <Img src={image?.filename} alt={image?.alt} />
      </div>
      <div css={S.cardDescription}>
        <div css={S.descriptionContent}>
          <Text as="h2">{title}</Text>
          <Text>
            Renda mínima: <span>{minimumIncome}</span>
          </Text>
          <Text>
            Anuidade: <span>{annuity}</span>
          </Text>
        </div>
        <Text>{limitText({ text: description, length: 140 })}</Text>
      </div>
      <div css={S.cardLinks}>
        <Button onClick={handleClick}>Peça já</Button>
        <Link to={fullSlug}>Saiba mais</Link>
      </div>
    </div>
  )
}

export default CardItem
