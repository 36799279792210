const useLoanItem = ({ loansInfo }) => {
  const infoSelectForShow = ["Valor", "Aceita negativado", "Parcelamento"]

  const sectionListInfoLoans = loansInfo.filter((info) => {
    const { title } = info
    return infoSelectForShow.includes(title)
  })

  return { sectionListInfoLoans }
}

export default useLoanItem
