// Hooks
import useAccordion from "./hooks"

// Styles
import * as S from "./styles"

const Accordion = ({ title, subtitle, field, type = "checkbox", options }) => {
  const { isAccordionOpen, handleToggleAccordion } = useAccordion()

  return (
    <div>
      <div
        css={S.accordion}
        className={`${isAccordionOpen ? "--open" : "--closed"}`}
        onClick={handleToggleAccordion}
      >
        <div
          css={S.accordionIcon}
          className={`${isAccordionOpen ? "--open" : "--closed"}`}
        />
        <p css={S.accordionTitle}>{title}</p>
        <span css={S.accordionSubtitle}>{subtitle}</span>
      </div>
      <div
        css={S.accordionContent}
        className={`${isAccordionOpen ? "--open" : "--closed"}`}
      >
        {options.map((option) => (
          <div css={S.contentOption} key={`field-accordion-${option.id}`}>
            <input
              type={type}
              id={option.value}
              name={field}
              value={option.value}
            />
            <label htmlFor={option.value}>{option.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Accordion
