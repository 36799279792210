// Styles
import * as S from "./styles"

const Drawer = ({ isOpen, handleToggleDrawer, children }) => {
  return (
    <div css={S.drawer} className={`${isOpen ? "--active" : "--inactive"}`}>
      <div css={S.drawerWrapper}>{children}</div>
      <div css={S.drawerOverlay} onClick={handleToggleDrawer} />
    </div>
  )
}

export default Drawer
