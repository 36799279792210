import DefaultLayout from "../../layout/DefaultLayout"
import SbEditable from "@mkt/ui/components/common/SbEditable"

// Hooks
import useListing from "@mkt/ui/components/common/Listing/hooks"

// Data
import {
  incomeOptions,
  filtersData,
} from "@mkt/ui/components/common/Listing/data"

// Components
import Button from "@mkt/ui/components/Azulis/Button"
import Drawer from "@mkt/ui/components/common/Drawer"
import Text from "@mkt/ui/components/Azulis/Text"
import Accordion from "./components/Accordion"
import ItemListing from "./components/ItemListing"

// Assets
import CardNotFound from "@mkt/ui/icons/Azulis/CardNotFound"

// Styles
import * as S from "./styles"
import Condition from "@mkt/ui/components/common/Condition"
import Loading from "@mkt/ui/components/common/Loading"

const Listing = ({ pageContext, location }) => {
  const {
    content,
    loading,
    products,
    sectionTitle,
    filters,
    namePage,
    isFilterOpen,
    formFilterRef,
    handleFilterSubmit,
    handleClearFilter,
    handleToggleFilter,
    handleCardClick,
  } = useListing({
    pageContext,
  })

  return (
    <DefaultLayout pageContext={pageContext} location={location}>
      <SbEditable content={content}>
        <div css={S.listingPage}>
          <div css={S.listingContainer} data-onaccess="productListViewed">
            <div css={S.listingFilter}>
              <Text as="h1">{sectionTitle}</Text>
              <Button size="small" onClick={handleToggleFilter}>
                Filtrar
              </Button>
            </div>
            <SbEditable content={content?.product_list[0]}>
              <div css={S.listingCards}>
                {loading && <Loading />}
                {!loading &&
                  products?.map((content, index) => (
                    <ItemListing
                      position={index}
                      handleClick={() =>
                        handleCardClick(content, index, content.full_slug)
                      }
                      page={namePage}
                      content={content}
                      key={`listing-azulis-${content._uid}-${index}`}
                    />
                  ))}
                {!loading && products.length <= 0 && (
                  <div css={S.emptyListingCard}>
                    <CardNotFound />
                    <p>Não encontramos nenhuma opção com estes filtros</p>
                  </div>
                )}
              </div>
            </SbEditable>
          </div>
        </div>
        <Drawer isOpen={isFilterOpen} handleToggleDrawer={handleToggleFilter}>
          <div css={S.filterHeader}>
            <a href="#" onClick={handleToggleFilter}>
              <div css={S.headerCloseIcon} />
            </a>
          </div>
          <form
            css={S.filterContent}
            onSubmit={handleFilterSubmit}
            ref={formFilterRef}
          >
            <Accordion
              title={filtersData[namePage]?.issuer?.title}
              subtitle={filtersData[namePage]?.issuer?.subtitle}
              field="issuer"
              type="radio"
              options={filters.issuers}
            />
            <Condition condition={namePage === "loans"}>
              <Accordion
                title="Garantia"
                subtitle="Filtre por tipo de garantia"
                field="assurance"
                type="radio"
                options={filters.assurances}
              />
            </Condition>
            <Condition condition={namePage === "acquirer"}>
              <Accordion
                title={filtersData[namePage]?.acquirerType?.title}
                subtitle={filtersData[namePage]?.acquirerType?.subtitle}
                field="acquirer_type"
                type="radio"
                options={filters.acquirerTypes}
              />
            </Condition>

            <Accordion
              title={filtersData[namePage]?.category?.title}
              subtitle={filtersData[namePage]?.category?.subtitle}
              field="category"
              type="radio"
              options={filters.categories}
            />
            <Condition condition={namePage === "accounts"}>
              <Accordion
                title={filtersData[namePage]?.accountType?.title}
                subtitle={filtersData[namePage]?.accountType?.subtitle}
                field="account_type"
                type="radio"
                options={filters.accountTypes}
              />
            </Condition>

            <Condition condition={namePage === "card"}>
              <Accordion
                title="Renda Mínima"
                subtitle="Filtre por renda mínima"
                field="income"
                type="radio"
                options={incomeOptions}
              />
            </Condition>
            <div css={S.contentButtonGroup}>
              <a href="#" onClick={handleClearFilter}>
                <div css={S.buttonGroupTrashIcon} />
                <span>Limpar filtros</span>
              </a>
              <Button type="secondary" htmlType="submit">
                Aplicar
              </Button>
            </div>
          </form>
        </Drawer>
      </SbEditable>
    </DefaultLayout>
  )
}

export default Listing
